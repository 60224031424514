import React from "react"

const headerStyle = {
    background: "#7EBEAB",
    width: "100%",
    height: "70px"
}

const headerTitle = {
    fontSize: "20px",
    padding: "20px 30px",
    color: "#ffffff",
    margin: "0 auto",
    fontWeight: "600",
    maxWidth: "800px"
}
export default () => (
  <header>
      <div style={headerStyle}>
          <p style={headerTitle}>法人カードおすすめ10選</p>
      </div>
  </header>
)