import React from "react";
import '../styles/card.css'
import { GatsbyImage, getImage } from "gatsby-plugin-image"


export default function Card(props) {
    console.log(props.content.title)
  return (
    <header>
      <div className="card">
        <div className="cardTitle">{props.content.title}</div>
        <div>
          <div className="cardImg">
            {/* <img className="img" src={`https://${props.content.image.file.url}`} /> */}
            <GatsbyImage image={props.content.image.gatsbyImageData} />
          </div>
        </div>
        <div className="cardContent">
          <p className="recommendTitle">おすすめポイント</p>
          <p className="recommendText">・{props.content.point1}</p>
          <p className="recommendText">・{props.content.point2}</p>
          <p className="recommendText">・{props.content.point3}</p>
          <p className="recommendText">・{props.content.point4}</p>
          <p className="recommendText">・{props.content.point5}</p>
          <div className="detailCardWrapper">
            <div className="flex card-info">
              <div className="detailCard">
                <div className="detailTitle">年会費</div>
                <div className="detailText">{props.content.annualFee}</div>
              </div>
              <div className="detailCard">
                <div className="detailTitle">追加カード年会費</div>
                <div className="detailText">
                  {props.content.additionalCardAnnualFee}
                </div>
              </div>
              <div className="detailCard">
                <div className="detailTitle">ETCカード年会費</div>
                <div className="detailText">{props.content.etcCardAnnualFee}</div>
              </div>
            </div>
            <div className="flex card-info">
              <div className="detailCard">
                <div className="detailTitle">ポイント還元率</div>
                <div className="detailText">
                  {props.content.pointRedemptionRate}
                </div>
              </div>
              <div className="detailCard">
                <div className="detailTitle">マイル還元率</div>
                <div className="detailText">
                  {props.content.mileageRedemptionRate}
                </div>
              </div>
              <div className="detailCard">
                <div className="detailTitle">申込資格 </div>
                <div className="detailText">
                  {props.content.applicationQualifications}
                </div>
              </div>
            </div>
            <div className="flex card-info">
              <div className="detailCard">
                <div className="detailTitle">必要書類 個人事業主</div>
                <div className="detailText">
                  {props.content.requiredDocumentsSoleProprietor}
                </div>
              </div>
              <div className="detailCard">
                <div className="detailTitle">必要書類 法人代表者 </div>
                <div className="detailText">
                  {props.content.requiredDocumentsLegalRepresentative}
                </div>
              </div>
              <div className="detailCard">
                <div className="detailTitle">限度額</div>
                <div className="detailText">{props.content.limit}</div>
              </div>
            </div>
            <div className="flex card-info">
              <div className="detailCard">
                <div className="detailTitle">国際ブランド</div>
                <div className="detailText">
                  {props.content.internationalBrands}
                </div>
              </div>
              <div className="detailCard">
                <div className="detailTitle">キャッシュフロー</div>
                <div className="detailText">{props.content.cashFlow}</div>
              </div>
              <div className="detailCard">
                <div className="detailTitle">会計ソフト自動連携</div>
                <div className="detailText">
                  {props.content.automaticLinkageWithAccountingSoftware}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttonWrapper">
          <a className="link" href={props.content.link}>
            <div className="button">お申し込みはこちら！</div>
          </a>
        </div>
      </div>
    </header>
  )
}
