import React from "react";
import Header from "./header";
import Footer from "./footer";
import "../styles/global.css";

// styles
const layout = {
  margin: "0 auto",
  maxWidth: "800px",
};
export default ({ children }) => (
  <>
    {" "}
    {/* <React.Fragment>の省略形 */}
    <Header />
    <div style={layout}>{children}</div>
    <Footer />
  </>
);
