import React from "react";

const footer = {
  marginTop: "100px",
  background: "#393939",
  color: "#fff",
  padding: "30px 30px 0 30px",
};

const footerContent = {
  maxWidth: "800px",
  margin: "0 auto"
}

const copyright = {
  marginTop: "40px",
  fontSize: "14px",
  textAlign: "center",
};
export default () => (
  <footer>
    <div style={footer}>
      <div style={footerContent}>
        <p>【当サイトに関する注意事項】</p>
        <p>
          当サイトで提供する商品の情報は、情報の正確性その他一切の事項についてを保証をするものではありません。
        </p>
        <p>
          当サイトのいかなる情報により生じた損失に対しても、当社は一切の責任を負いません。
        </p>

        <p style={copyright}>Copyright (C) 2022 Kanekoya, inc.</p>
      </div>
    </div>
  </footer>
);
