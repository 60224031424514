import React from "react";
import Layout from "../components/layout";
import Card from "../components/card";
import { graphql } from "gatsby";
import "../styles/index.css";

export default function Home({ data }) {
  return (
    <Layout>
      <div className="banner"></div>
      <div className="headline">
        <p className="headlineText">法人カードを利用するメリット</p>
      </div>
      <div className="meritt-wrapper">
        <div className="flex">
          <div className="meritt">
            <p className="merittText">
              1. {data.allContentfulBaseContents.edges[0].node.merrit1}
            </p>
          </div>
          <div className="meritt">
            <p className="merittText">
              2. {data.allContentfulBaseContents.edges[0].node.merrit2}
            </p>
          </div>
        </div>
        <div className="flex">
          <div className="meritt">
            <p className="merittText">
              3. {data.allContentfulBaseContents.edges[0].node.merrit3}
            </p>
          </div>
          <div className="meritt">
            <p className="merittText">
              4. {data.allContentfulBaseContents.edges[0].node.merrit4}
            </p>
          </div>
        </div>
      </div>
      <p className="mainTitle">法人カードおすすめ10選</p>
      {data.allContentfulCardInfo.edges.map((edge) => (
        <Card key={edge.node.id} content={edge.node} />
      ))}
    </Layout>
  );
}

export const query = graphql`
  query allContentfulBaseContents {
    allContentfulBaseContents {
      edges {
        node {
          contentful_id
          createdAt
          id
          merrit1
          merrit2
          merrit3
          merrit4
          node_locale
          spaceId
          updatedAt
        }
      }
    }
    allContentfulCardInfo {
      edges {
        node {
          cashFlow
          applicationQualifications
          automaticLinkageWithAccountingSoftware
          annualFee
          additionalCardAnnualFee
          contentful_id
          etcCardAnnualFee
          id
          limit
          link
          internationalBrands
          mileageRedemptionRate
          node_locale
          point1
          point2
          point3
          point4
          point5
          pointRedemptionRate
          requiredDocumentsLegalRepresentative
          spaceId
          requiredDocumentsSoleProprietor
          title
          image {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
